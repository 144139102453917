@import "../../../styles/partials/functions";


.drawer-section {
  margin-bottom: getPxValue( 4 );

  h4 {
    margin-bottom: getPxValue( 3 );
  }

  input::placeholder {
    opacity: 1;
  }

  .ground-text-input,
  .ground-select,
  .ground-date-range-picker-input {
    font-size: 0.875rem;
  }

  .ground-select {
    .trigger-button:not(.waiting) {
      font-weight: $fontWeightBold;
    }
  }

  .ground-toggle-wrapper {
    font-weight: $fontWeightMedium;
    text-transform: uppercase;
  }
}