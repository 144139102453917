@import "../../../styles/partials/functions";


$listItemTopPad: getPxValue( 2 );

.list-of-trips {
  overflow-y: auto;

  li {
    padding: #{$listItemTopPad} 0;
  }

  .id-column {
    line-height: 1;
    text-align: center;
    margin-bottom: calc( -1 * $listItemTopPad );
    padding: 0.2rem 0.5rem #{$listItemTopPad};
    
  }
 

  .details-column {
    font-size: 0.75rem;
    line-height: 1.5em;
    padding: 0 #{getPxValue()};
  }

  .ground-checkbox {
    align-self: center;
    margin: 0;
  }

  .trip-id {
    flex-direction: column;
    display: flex;
    font-size: 1.125rem;
    line-height: 1.2em;
    margin-bottom: 0.4rem;

    &::after {
      border-radius: 3px;
      content: "";
      display: block;
      height: 4px;
      width: 100%;
    }
  }

 

  .score-label {
    font-size: 0.625rem;
    line-height: 1.5em;
    text-transform: uppercase;
  }

  .score {
    font-size: 0.875rem;
  }
}