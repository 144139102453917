@import "../../styles/partials/functions";

.main-footer {
  border-top-width: 1px;
  border-top-style: solid;
  box-sizing: border-box;
  font-size: 0.65rem;
  flex-direction: row-reverse;
  min-height: $footerHeight;
  padding: 0 #{getPxValue( 2 )};

  .copyrights {
    flex-grow: 1;
  }
}