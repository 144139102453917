@import "../../styles/partials/functions";


$padding: getPxValue( 3 );
$residenceBoxMarginBottom: $padding;

.details-page {
  .dpage-card {
    padding: $padding;
  }

  .sidebar {
    height: $detailsPageSidebarHeight;
    max-height: $detailsPageSidebarHeight;
    width: $detailsPageSidebarWidth;

    &.profile-details-sidebar {
      height: $profileDetailsSidebarHeight;
      max-height: $profileDetailsSidebarHeight;

      &.no-claim-amount-box {
        height: $profileDetailsSidebarHeightWithoutClaimAmountBox;
        max-height: $profileDetailsSidebarHeightWithoutClaimAmountBox;
      }
    }
  }

  .dpage-chart-wrapper {
    height: $detailsPageChartHeight;
    padding-top: getPxValue( 2.5 );
  }

  .dpage-residence-box-card {
    height: $detailsPageResidenceBoxHeight;
    margin-bottom: $residenceBoxMarginBottom;
  }

  .dpage-trips-card {
    height: 100%;

    &.no-claim-amount-box {
      height: calc( #{$profileDetailsSidebarHeightWithoutClaimAmountBox} - #{$residenceBoxMarginBottom} - #{$detailsPageResidenceBoxHeight} );
    }

    &.resized {
      height: calc( #{$profileDetailsSidebarHeight} - #{$residenceBoxMarginBottom} - #{$detailsPageResidenceBoxHeight} );
    }
  }

  .actions-form {
    gap: getPxValue( 1.5 );

    .ground-select {
      font-size: 0.75rem;

      .trigger-button:not(.waiting) {
        font-weight: $fontWeightBold;
      }
    }

    .ground-textarea {
      display: block;
      font-size: 0.875rem;
    }
  }
}

.list-of-trips-sidebar {
  .title {
    text-transform: uppercase;
    margin-bottom: 0.3em;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.4em;
  }
}

.list-of-trips-wrapper {
  max-height: 100%;
}