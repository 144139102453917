@import "./variables";

::-webkit-scrollbar {
  width: 8px;
  z-index: 1;
}

::-webkit-scrollbar-track {
 background: $white ;
}

::-webkit-scrollbar-thumb {
 background: $monochromeGreyLight ;
 border-radius: 15px ;
 height: 50px ;
}

::-webkit-scrollbar-thumb:hover {
 background: $monochromeGreyDarker;
}
