@import "../../styles/partials/functions";


$formPadding: getPxValue( 3 );

.login-template {
  background-image: url( '/images/background.svg' );
  background-repeat: no-repeat;
  background-position: center;
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  flex-direction: column;

  .page-content {
    flex-grow: 1;
  }

  .form-wrapper {
    border-style: solid;
    border-width: 1px;
    border-radius: $borderRadiusBox;
    box-sizing: border-box;
    margin: #{getPxValue( 3 )} 0;
    min-height: $loginBoxMinHeight;
    padding: $formPadding;
    text-align: center;
    width: $loginBoxWidth;

    h1 {
      align-items: center;
      border-top-left-radius: $borderRadiusBox;
      border-top-right-radius: $borderRadiusBox;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      display: flex;
      height: $loginBoxHeaderHeight;
      justify-content: center;
      margin: -1 * $formPadding;
      margin-bottom: 0;
    }
  
    form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  
    h3 {
      font-weight: $fontWeightMedium;
      line-height: 2em;
      margin-top: getPxValue( 3 );
    }
  
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      margin-top: getPxValue( 0.5 );
      margin-bottom: getPxValue( 4 );
    }
  
    .form-controls {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: getPxValue( 5 );
      padding: 0 10%;
    }
  
    .error-message {
      display: block;
      font-size: 0.75rem;
      text-align: left;
    }
  
    .buttons {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      justify-content: space-between;
      margin-right: -10%;
    }

    .ground-button {
      min-width: getPxValue( 13 );
    }
  }
}

