@import "../../../styles/partials/functions";


.number-of-results-menu {
  display: flex;
  
  .item {
    border-width: 1px;
    border-style: solid;
    font-size: 0.875rem;
    line-height: 1;
    min-width: 2.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    &:disabled {
      cursor: default;
    }

    &.active {
      font-weight: $fontWeightBold;
    }

    &:not(:first-of-type) {
      border-left-width: 0;
    }

    &:first-of-type {
      border-top-left-radius: $borderRadiusBox;
      border-bottom-left-radius: $borderRadiusBox;
    }

    &:last-of-type {
      border-top-right-radius: $borderRadiusBox;
      border-bottom-right-radius: $borderRadiusBox;
    }
  }
}