@import "../../../styles/partials/functions";


.tarea-claims {
  .column-head-severity,
  .column-head-fraud_probability,
  .cell-severity,
  .cell-fraud_probability {
    text-align: center;
  }
}
