@import "../../../../styles/partials/functions";


.pdetails-main-sidebar-section {
  padding: getPxValue( 3 );

   p {
    margin-top: 0.3rem;
  }

  .title {
    text-transform: uppercase;
    margin-bottom: 10px;

    &.no-margin {
      margin: 0;
    }
  }

  .sub-title{
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 16px;
      font-size: 1rem;
    
  }

  .risk-events-data-column {
    min-width: 40%;
    max-width: 40%;
  }

  .risk-events-info {
    .value {
      font-size: 1.5rem;
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .residence-statistics-container{
      margin-top: getPxValue( 3 );
  }
}
