@import "../../../../styles/partials/functions";

.cdetails-main-sidebar-content {
  padding: getPxValue( 3 );
  padding-bottom: getPxValue();
  gap: 14px;
  h3 {
    margin-bottom: getPxValue( 2 );
    text-transform: uppercase;
  }

  p {
    margin-top: 0.3rem;
  }

  .claim-info-section-wrapper {
    // 0.2 = 20%
    min-height: 0.2 * $detailsPageSidebarHeight;
  }

  .claim-info-section {
    max-height: 100%;
    width:100%;
    h3 {
      margin-bottom: getPxValue();
    }

    .location {
      font-weight: $fontWeightBold;
      overflow: hidden;
    }
    
    .severity-budge {
      margin-top: getPxValue( 2 );
    }
  }

  .fraud-anomaly-section {
    font-size: 0.875rem;

    p:first-of-type {
      margin-top: 0;
      margin-bottom: 0.8rem;
    }

    .probability-value {
      font-size: 1.5rem;
    }
  }
}