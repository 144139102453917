@import "../../styles/partials/functions";

.filters-drawer-wrapper {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  z-index: $zindexModal;

  &.show {
    width: 100%;
  }
}

.filters-drawer {
  box-sizing: border-box;
  margin-right: -$modalDrawerWidth;
  overflow-y: auto;
  padding: getPxValue( 3 );
  transition: margin-right 0.3s;
  width: $modalDrawerWidth;

  &.showFilter {
    margin-right: 0;

    .toggle-button {
      margin-right: $modalDrawerWidth;
    }
  }

    &:not(.showFilter) {
      &:not(.filterButtonOnDrawer) {
        .toggle-button {
          visibility: hidden;
        }
      }
    }

  .toggle-button {
    box-sizing: border-box;
    border-top-left-radius: $borderRadiusBox;
    border-bottom-left-radius: $borderRadiusBox;
    right: 0;
    min-height: $modalDrawerCloseBtnMinHeight;
    padding-left: getPxValue();
    position: absolute;
    top: getPxValue( 27 );
    transition: margin-right 0.3s;
    width: $modalDrawerCloseBtnWidth;
  }

  .header {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-top: getPxValue();
    padding-bottom: getPxValue( 2.5 );
    margin-bottom: getPxValue( 3 );
  }
}