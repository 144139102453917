@import "../../../styles/partials/functions";

.textinput-wrapper {
  position: relative;
  
  .ground-text-input-wrapper.space-letters {
    .password {
      letter-spacing: 0.5rem;
    }
  }

  .validation-error {
    $fontSize: 0.75rem;
    
    bottom: calc( -1 * ( $fontSize + getPxValue( 1 ) ) );
    font-size: $fontSize;
    left: 0;
    line-height: 1;
    margin-left: getPxValue( 2 );
    position: absolute;
    text-align: left;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
