html {
  // !important needed to overcome 
  // a conflict the Ground package.
  font-size: #{$bodyFontSize} !important;
}

body {
  font-family: $fontFamilyPrimary;
  font-weight: $fontWeightRegular;
  line-height: $bodyLineHeight;

  // ---
  // The following rules are nested to overcome 
  // a conflict with the Ground package.
  // ---
  
  h1, h2, h3, h4 {
    line-height: 1;
    font-weight: $fontWeightBold;
  }
  
  h2 {
    font-size: $fontSizeH2;
  }
  
  h3 {
    font-size: $fontSizeH3;
  }
}

p {
  line-height: $bodyLineHeight;
}

a {
  text-decoration: none;
}

strong,
.text-bold {
  font-weight: $fontWeightBold;
}

.text-semibold {
  font-weight: $fontWeightMedium;
}

.uppercase {
  text-transform: uppercase;
}

.text-small {
  font-size: 0.875rem;
}

.text-xsmall {
  font-size: 0.75rem;
}