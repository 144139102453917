@import "../../styles/partials/functions";


$tableHeadHeight: getPxValue( 7 );

.table-area {
  padding: getPxValue( 3 );
  padding-bottom: getPxValue( 2 );

  .tarea-header{
    display: flex;
    align-items: center;
    margin-bottom: getPxValue(3);
    .input-container  {
      display: flex;
      margin-left: getPxValue(2);

      .input-searchbar-table{
        .ground-text-input-toggle-btn {
          pointer-events: none;   
      }
      }
    }
    
    .filter-button{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #20A8BF;
      box-sizing: border-box;
      border-radius: $borderRadiusBox;
      min-height: $modalDrawerCloseBtnMinHeight;
      width: $modalDrawerCloseBtnWidth;
      margin-left: getPxValue(2);

    }
  }
 

  .table-wrapper {
    $rowBorderIndentation: getPxValue( 2 );

    border-width: 1px;
    border-style: solid;
    border-top-left-radius: $borderRadiusBox;
    border-top-right-radius: $borderRadiusBox;
    position: relative;

    .table-update-indicator {
      box-sizing: border-box;
      max-height: 500px;
      padding-top: $tableHeadHeight;
      position: absolute;
      top: 0;
      z-index: $zindexPopover;
    }

    &.updating {
      tr,
      button {
        pointer-events: none;
      }

      .ground-table {
        .cell-content {
          opacity: 0.3;
        }
      }
    }
    
    .ground-table {
      box-shadow: none;

      td,
      th {
        font-size: 0.875rem;
        line-height: 1.3em;

        &:first-of-type {
          padding-left: $rowBorderIndentation;
        }

        &:last-of-type {
          padding-right: $rowBorderIndentation;
        }
      }

      th {
        font-weight: $fontWeightMedium;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        height: $tableHeadHeight;
      }

      td {
        border: none;
        padding-top: getPxValue( 2.5 );
        padding-bottom: getPxValue( 2.5 );
      }

      tr.selected {
        cursor: pointer;
      }

      .sort-button {
        text-align: left;
        
        &.ascending,
        &.descending {
          .sort-icon {
            position: relative;
            transform: rotate( -90deg );
            width: 1.1rem;

            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              right: 0;
            }

            &::before {
              border-width: 2px 2px 0 0;
              border-style: solid;
              height: 0.5rem;
              width: 0.5rem;
              transform: rotate( 45deg );
            }

            &::after {
              height: 2px;
              width: getPxValue( 2 );
            }
          }
        }

        &.descending {
          .sort-icon {
            transform: rotate( 90deg );
          }
        }
      }

      .cell-utility {
        padding-left: 0;

        .cell-content {
          display: flex;
          justify-content: flex-end;
        }

        .arrow {
          margin-right: -0.3rem;
        }
      }

      tbody {
        tr:not(:first-child) {
          td {
            position: relative;

            &::before {
              content: "";
              display: block;
              border-width: 1px 0 0;
              border-style: solid;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }

            &:first-of-type,
            &:last-of-type {
              &::before {
                width: calc( 100% - $rowBorderIndentation );
              }
            }

            &:first-of-type {
              &::before {
                left: $rowBorderIndentation;
              }
            }

            &:last-of-type {
              &::before {
                left: auto;
                right: $rowBorderIndentation;
              }
            }
          }
        }
      }
    }

    .no-content {
      height: 5rem;
    }
  }

  .id-column-width {
    width: 4.5rem;
  }

  .collapse-width {
    width: 0;
  }

  .pagination-wrapper {
    margin-top: getPxValue( 2 );

    .ground-pagination-menu {
      .item {
        font-size: 0.875rem;
        min-width: 2.5rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        &.active {
          font-weight: $fontWeightBold;
        }
      }
  
      .next,
      .prev {
        &::before {
          border-width: 0 0 2px 2px;
          height: 0.45em;
          width: 0.45em;
        }
      }
    }
  }
}