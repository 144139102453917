
.long-text-tooltip-wrapper {
  display: inline-block;
  position: relative;

  &:hover .tooltip {
    display: block;
  }

  &.break-words, &.break-words .tooltip {
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .tooltip {
    border-radius: 4px;
    box-shadow: 0px 2px 4px #07172258;
    display: none;
    font-size: 0.9em;
    font-weight: normal;
    left: 0;
    line-height: 1.5em;
    padding: 0.5rem;
    position: absolute;
    top: 0;
    z-index: 5;
  }
}