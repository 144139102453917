@import "../../../styles/partials/functions";


.details-page-modal {
  p {
    font-size: 0.875rem;
    margin: 0.5rem 0 0;
    text-align: left;
  }

  .modal-data-group {
    margin-bottom: 16px;
    position: relative;
    h2 {
      color: $deepBlueWashedOut;
      font: normal normal normal 16px/21px Roboto;
      margin-bottom:16px;
    }
    .modal-data-group-title-box {
      background-color: $turquoise;
      height: 32px;
      left: -24px;
      position: absolute;
      top: -6px;
      width: 18px;
    }
  }
}
