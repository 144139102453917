@import "../../styles/partials/functions";

.error-page {
    background-image: url( '/images/background.svg' );
    background-repeat: no-repeat;
    background-position: center;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
    .error-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 8.125rem;
            text-align: center;
            margin-bottom: getPxValue( 8 );
        }
       
        h2 {
            font-size: 2.375rem;
            text-align: center;
            margin-bottom: getPxValue( 3 );
        }
        
        p {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: getPxValue( 8 );
        }
    }
}