@import "../../../styles/partials/functions";

.trends-page {
  .trigger-buttons-wrapper{
    margin-top: getPxValue( 2 );
    
    button {
      border-style: solid;
      border-bottom-width: 1px;
      height: getPxValue( 4.5 );
      padding-top: getPxValue();
      text-transform: uppercase;
  
      &.selected {
        border-bottom-width: 2px;
        font-weight: $fontWeightBold; 
      }
    }
  }

  .chart-wrapper {
    height: $trendsChartHeight;
  }
}



