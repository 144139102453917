@import "../../../styles/partials/functions";

.login-form {
  .submit-wrapper {
    .error-message {
      $minHeight: getPxValue( 3 );

      box-sizing: border-box;
      margin-top: -1 * $minHeight;
      min-height: $minHeight;
      padding-bottom: getPxValue( 1 );
    }
  }

  .link-to-recovery {
    margin-top: getPxValue( -1 );
  }
}