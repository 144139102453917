
.last-trip-modal {
    display: flex;
    align-items: center;
    
    p {
        font-size: 0.875rem;
        margin: 0.5rem 0 0;
        white-space: pre-line;
    }
}
