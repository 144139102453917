// Base Font Size
$bodyFontSize: 17px;

// Base Space
$baseSpace: 8px;

// Structure
$bodyMinWidth: 1440px;
$bodyMinHeight: 800px;
$headerHeight: 82px;
$footerHeight: 24px;
$utilityHeaderHeight: 120px;
$utilityHeaderOverlap: 80px;
$mainContentTopPad: $baseSpace * 2;
$mainContentRightPad: $baseSpace * 3;
$mainContentBottomPad: $baseSpace * 2;
$mainContentLeftPad: $baseSpace * 4;

// Login
$loginBoxMinHeight: 570px;
$loginBoxWidth: 450px;
$loginBoxHeaderHeight: 150px;

// Dashboard Widgets
$dashboardWidgetPadding: $baseSpace * 3;
$dashboardWidgetTopPadding: $baseSpace * 4;
$dashboardWidgetDataAreaHeight: 120px;

// Details Pages
$detailsPageSidebarWidth: 330px;
$detailsPageSidebarHeight: 720px;
$detailsPageChartHeight: 250px;
$detailsPageResidenceBoxHeight: 130px;
$profileDetailsSidebarHeight: 1150px;
$profileDetailsSidebarHeightWithoutClaimAmountBox: 980px;

// Trends Page
$trendsWidgetHeight: 100px;
$trendsChartHeight: 500px;

// Modals
$modalMenuMinWidth: 230px;
$modalMenuButtonHeight: 48px;
$modalWindowWidth: 400px;
$modalDrawerWidth: 430px;
$modalDrawerCloseBtnWidth: 42px;
$modalDrawerCloseBtnMinHeight: 32px;

// Typography
$bodyLineHeight: 1.25em; // 20px
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;
$fontFamilyPrimary: 'Roboto', sans-serif;
$fontSizeH2: 1.5rem;
$fontSizeH3: 1.125rem;

// Z-index
$zindexPopover: 10;
$zindexModal: 1000;
$zindexModalWindow: 3000;

// Box Shadow
$boxShadowHeader: 0px 0px 20px #353B401A;
$boxShadowModalMenu: 0px 2px 4px #0E263629;
$boxShadowDatePicker: 0px 1px 4px #00000050;

// Border Radius
$borderRadiusBox: 8px;
$borderRadiusInput: 4px;

// Colors
$black                   : #0E2636;
$blueGrey                : #B3CADB;
$blueGreyDark            : #80a2bc;
$blueGreyLight           : #E6EDF3;
$blueGreyLighter         : #99B8CE;
$blueGreyLightest        : #F5F8FA;
$brightBlue              : #0571DD;
$cloudyBlue              : #7EB3BF;
$deepBlue                : #004E85;
$deepBlueAlpha           : #004E8533;
$deepBlueDark            : #004373;
$deepBlueDarker          : #002B49;
$deepBlueLight           : #0064ab;
$deepBlueLightest        : #F5F8FA;
$deepBlueWashedOut       : #5B7383;
$deepBlueWashedOutDark   : #425A6A;
$deepBlueWashedOutDarker : #41535f;
$grass                   : #A7BF20;
$green                   : #488C21;
$greenLight              : #47A03F;
$grey                    : #98a0a6;
$greyDark                : #425360;
$greyDarker              : #383839;
$greyLight               : #687680;
$greyLighter             : #A6B0B6;
$monochromeGrey          : #DDDDDD;
$monochromeGreyDark      : #ACB8C1;
$monochromeGreyDarker    : #BDBDBD;
$monochromeGreyLight     : #E1E1E1;
$monochromeGreyLighter   : #EAECEF;
$monochromeGreyLightest  : #F9F9F9;
$mud                     : #8C8369;
$mustard                 : #D99C2B;
$nightBlueWashedOut      : #284050;
$orange                  : #F46A00;
$red                     : #DD0546;
$turquoise               : #20A8BF;
$turquoiseDark           : #1a8293;
$turquoiseLight          : #25b9d8;
$turquoiseWashedOut      : #80bcc8;
$turquoiseWashedOutDark  : #5f8e97;
$white                   : #FFFFFF;
