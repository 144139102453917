@import "../../../../styles/partials/functions";

.trends-widget {
  height: $trendsWidgetHeight;
  box-sizing: border-box;
  padding: #{getPxValue( 2.3 )};
  
  .title {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.2em;
  }

  .value,
  .unit {
    line-height: 1.1em;
  }

  .value{
    font-size: 1.5rem;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    display: block;

    &.text {
      font-size: 1.3rem;
    }

    &.number {
      text-align: center;
    }
  }

  .unit{
    font-size: 1.125rem;
    margin-left: 0.5rem;
  }

  .placeholder {
    line-height: 2rem;
  }
}

