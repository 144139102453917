@import "../../styles/partials/functions";

.confirmation-modal {
  border-radius: $borderRadiusBox;
  box-sizing: border-box;
  padding: getPxValue( 3 );
  position: relative;
  width: $modalWindowWidth;

  h3 {
    font-size: 1rem;
    margin: 0 0 #{getPxValue( 3 )};
    text-align: left;
    text-transform: uppercase;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.45em;
    margin-bottom: getPxValue( 2 );
  }

  .close-button {
    position: absolute;
    right: getPxValue( 3 );
    top: getPxValue( 3 );
  }

  .action-buttons {
    gap: getPxValue();
    margin-top: getPxValue( 5 );
  }
}