@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.tarea-profiles {
  .cell-score {
    text-align: center;
  }

  .cell-skeleton {
    background-color: rgba( 0, 0, 0, 0.1 );
    border-radius: 2px;
    flex-grow: 1;
    height: 1em;
    min-width: 50px;
    animation: pulse 1s ease-in-out infinite;
  }

  .risk-events-data-column {
    align-content: start;
    display: grid;
    text-transform: lowercase;
    grid-template-columns: auto auto;
    gap: 0.3em;

    strong {
      text-align: right;
    }
  }
}