@import "../../styles/partials/functions";

.request-indicator-wrapper {
  font-size: 1.25rem;
  text-align: center;

  .error {
    font-weight: $fontWeightMedium;
  }

  p {
    font-size: 0.7em;
    margin-top: getPxValue( 1.5 );
  }
}