@import "../../styles/partials/functions";


.utility-header {
  height: $utilityHeaderHeight;
  padding-left: $mainContentLeftPad;
  padding-right: $mainContentRightPad;

  &.relaxed {
    padding-bottom: $utilityHeaderOverlap;
  }

  .breadcrumbs {
    .back-link {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;

      a:hover {
        text-decoration: none;
      }
    }

    .arrow {
      margin: 0 0.2rem -0.2rem 0.5rem;
    }

    .last-bc {
      font-weight: $fontWeightRegular;
    }
  }

  .toolbar {
    .toggle,
    .export-button {
      text-transform: uppercase;
    }
  }

  .utility-box {
    border-radius: $borderRadiusInput;
    border-style: solid;
    border-width: 1px;
  }

  .utility-label {
    font-size: 0.875rem;
  }

  .toggles-wrapper {
    $sidePadding: getPxValue( 3 );

    margin-right: getPxValue();
    padding-left: $sidePadding;
    padding-right: $sidePadding;
  }

  .utility-export-menu {
    right: 0;
    min-width: 150px;
    top: 115%;
    white-space: nowrap;
  }
}


.date-range-picker-wrapper {
  // Automatically computed by the 'DisclosingArrow' component 
  // according to the value of the 'scale' prop.
  $disclosingArrowWidth: 8px;
  
  $dividerWidth: 0.4rem;
  $sidePadding: getPxValue( 2 );
  $toggleWidth: $disclosingArrowWidth + $sidePadding;

  label {
    padding-left: $sidePadding;
    padding-right: calc( $sidePadding / 2 );
  }

  .ground-date-range-picker {
    background: none;
    border: none;

    &.disabled {
      background: none;
    }

    &:not(.picker-opened) {
      .toggle-arrow {
        transform: rotate( 180deg );
        margin-top: 0.2rem;
      }
    }

    .start-date-input,
    .end-date-input {
      font-size: 0.875rem;
      font-weight: $fontWeightBold;

      &::placeholder {
        font-weight: $fontWeightRegular;
        font-size: 14px; // Must be a fixed value
        opacity: 1;
      }
    }

    .start-date-input {
      padding: 0;
      padding-right: $dividerWidth;
      text-align: right;
    }

    .end-date-input {
      padding-left: $dividerWidth;
      padding-right: getPxValue( 0.5 ) + $toggleWidth;
    }

    .arrow {
      &, &::before {
        min-width: $dividerWidth;
      }

      &::before {
        border-bottom-width: 2px;
      }

      &::after {
        display: none;
      }
    }

    .toggle {
      width: $toggleWidth;
    }
  }
}