.router-link {
  font-size: 0.875rem;
  
  a:hover {
    text-decoration: underline;
  }
  
  a:active {
    text-decoration: none;
  }
}