.manual-claims-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .description-span {
        font-size: 0.75rem;
        font-weight: bold;
        color: #20A8BF;
    }

    .places-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input-searchbar {
            display: flex;
            gap: 8px;
            flex-direction: column;

            .ground-text-input-wrapper {
                width: 100%;
            }

            input {
                width: 100%;
                height: 34px;
                transition: border-radius 0.3s ease;
            }

             input:focus {
                border: 1px solid #004E85;
                outline: none;
            }
              input.has-text {
                outline: none;
                border: 1px solid #004E85;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
        }

        .autocomplete-results {
            position: absolute;
            top: calc(100% - 3px);
            left: 0;
            z-index: 10;
            background-color: #fff;
            border: 1px solid #004E85;
            border-top: none;
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;
            box-sizing: border-box;
            font-size: 0.875rem;
            border-bottom-left-radius:4.14px;
            border-bottom-right-radius:4.14px;

        }
      
        .autocomplete-item {
            padding: 8px 16px;
            cursor: pointer;
            box-sizing: border-box;

        }

        .autocomplete-item:hover {
            background-color: #f0f0f0;
        }
    }
    
    .form-manual-claim-details {
        gap: 10px;
        display: flex;
        flex-direction: column;

        .date-picker-container {
            flex-wrap: wrap;
            gap: 8px;
            flex-direction: column;

            input {
                width: auto
            }

        }

        .anomaly-type-container {
            gap: 8px;
            display: flex;
            flex-direction: column;
        }

        .save-button-wrapper {
            margin-top: 8px;
            display: flex;
            gap : 30px;
            justify-content: center;

            .cancel-button{
              text-transform: uppercase;
              padding-left: 1rem;
              padding-right: 1rem;       
            }
        }
    }
}
