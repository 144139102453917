@import "../../styles/partials/functions";
@import "../../styles/partials/variables";

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      border-radius: $borderRadiusInput;
      border: 1px solid $blueGreyLighter;
      font-family: $fontFamilyPrimary;
      font-size: 15px;
      height: 34px;
      outline: none;
      padding: 0 getPxValue(2);
      width: calc(100% - (2 * getPxValue(2))) !important;
      &:focus {
        border-color: $deepBlue;
        color: $deepBlue;
      }
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    z-index: 2;
    .react-datepicker {
      border: none;
      box-shadow: $boxShadowDatePicker;
      padding: 8px;
      .react-datepicker__triangle {
        background-color: red;
        height: 100px;
        &::before {
          border-bottom-color: $monochromeGreyLighter;
        }
        &::after {
          border-bottom-color: $white;
        }
      }
      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: $white;
          border: none;
          .react-datepicker__current-month {
            color: $deepBlueDarker;
          }
          .react-datepicker__day-names {
            margin-top: 8px;
            .react-datepicker__day-name {
              color: $deepBlueDarker;
              line-height: 24px;
              margin: 0;
              text-align: center;
              width: 40px;
            }
          }
        }
        .react-datepicker__month {
            font-family: $fontFamilyPrimary;
          .react-datepicker__week {
            .react-datepicker__day {
              border-radius: 0;
              border: 1px solid $monochromeGreyLighter;
              color: $deepBlueDarker;
              height: 40px;
              line-height: 40px;
              margin: 0;
              width: 40px;
              &:hover {
                background-color: $deepBlueAlpha;
              }
            }

            .react-datepicker__day--selected {
              background-color: $deepBlue;
              color: $white;
              &:hover {
                background-color: $deepBlue;
              }
            }
            .react-datepicker__day--outside-month {
              color: $deepBlueWashedOut;
            }
            .react-datepicker__day--disabled {
              opacity: 0.5;
              pointer-events: none; 
              cursor: not-allowed; 
            }
            
            .react-datepicker__day--disabled:hover {
              background-color: $white; 
              cursor: not-allowed;
            }
          }
        }
      }

      .react-datepicker__time-container{
          border: none;
          color: $deepBlueDarker;
          font-family: $fontFamilyPrimary;
        .react-datepicker__header{
          background-color: $white;
          border: none;
          .react-datepicker-time__header{
            color: $deepBlueDarker;
          }
        }
      }

      .react-datepicker__time{
        .react-datepicker__time-box{
          .react-datepicker__time-list{
            .react-datepicker__time-list-item{
              line-height: getPxValue(2);
              height: getPxValue(2);
              &:hover {
                background-color: $deepBlueAlpha;
              }
            }
            .react-datepicker__time-list-item--selected{
                background-color: $deepBlue;
              &:hover {
                background-color: $deepBlue;
              }
            }
          }
        }
      }
    }

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        &::before {
          border-color: $deepBlueDarker;
          top: 16px;
        }
        &:hover {
          &::before {
            border-color: $deepBlue;
          }
        }
      }
    }
  }
}
