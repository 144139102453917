.disclosing-arrow {
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0.1rem;
    position: absolute;
  }

  &::before {
    left: 0;
    transform: rotate( -40deg );
  }

  &::after {
    right: 0;
    transform: rotate( 40deg );
  }
}